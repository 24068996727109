<template>
  <div>
    <div class="user-card mobile">
      <div :class="['card-header', { 'card-editable': editModeOn }]">
        <div class="person-avatar">
          <img
            :src="userAvatar"
            alt="person avatar"
            height="65"
            width="65"
          >
          <div v-if="editModeOn">
            <input id="image-input" type="file" @change="changeImage($event)">
            <label for="image-input">
              <BRIcon
                class="edit-image"
                height="30"
                icon="edit"
                width="30"
              />
            </label>
          </div>
        </div>

        <div class="person-info">
          <div class="person-name">
            <span v-if="!editModeOn">
              {{ userName }}
            </span>
            <BRInput
              v-else
              :value.sync="userData.username"
              class="edit-input"
              @pressEnter="toggleEditMode"
            />
          </div>

          <div class="person-description">
            <span v-if="!editModeOn">
              {{ userData.description }}
            </span>
            <BRTextarea
              v-else
              :rows="2"
              :value.sync="userData.description"
              class="edit-input"
              @pressEnter="toggleEditMode"
            />
          </div>
        </div>

        <div v-if="!editModeOn" class="icon-container" @click="toggleEditMode">
          <BRIcon
            class="preferences"
            height="16"
            icon="preferences"
            width="16"
          />
        </div>
        <div v-else class="btn-save">
          <BRButton
            class="save"
            dark
            height="20"
            @click="saveUserData"
          >
            save
          </BRButton>
        </div>
      </div>
      <div class="card-main">
        <v-select
          v-if="getIsAdmin"
          v-model="navigationItem"
          :items="adminNavigationList"
          class="select"
          height="50"
          hide-details
          item-text="title"
          item-value="component"
          label="Views"
          return-object
          solo
          @change="changeActiveItem"
        >
          <template #item="{item}">
            <p class="select-item">
              {{ item.title }}
            </p>
          </template>
        </v-select>
        <v-select
          v-else
          v-model="navigationItem"
          :items="navigationList"
          class="select"
          color="var(--secondary-text-color)"
          height="50"
          hide-details
          item-color="var(--primary-text-color)"
          item-text="title"
          item-value="component"
          label="Views"
          return-object
          solo
          @change="changeActiveItem"
        >
          <template #item="{item}">
            <p class="select-item">
              {{ item.title }}
            </p>
          </template>
          <template #selection="{item}">
            <p class="selected-item">
              {{ item.title }}
            </p>
          </template>
        </v-select>
      </div>
    </div>
    <div class="user-card desktop">
      <div :class="['card-header', { 'card-editable': editModeOn }]">
        <div v-if="!editModeOn" @click="toggleEditMode">
          <BRIcon
            class="preferences"
            height="16"
            icon="preferences"
            width="16"
          />
        </div>
        <div v-else>
          <BRButton
            class="save"
            dark
            height="20"
            @click="saveUserData"
          >
            save
          </BRButton>
        </div>

        <div class="person-avatar">
          <img
            :src="userAvatar"
            alt="person avatar"
            height="100"
            width="100"
          >
          <div v-if="editModeOn">
            <input id="image-input" type="file" @change="changeImage($event)">
            <label for="image-input">
              <BRIcon
                class="edit-image"
                height="30"
                icon="edit"
                width="30"
              />
            </label>
          </div>
        </div>

        <div class="person-info">
          <div class="person-name">
            <span v-if="!editModeOn">
              {{ userName }}
            </span>
            <BRInput
              v-else
              :value.sync="userData.username"
              class="edit-input"
              @pressEnter="toggleEditMode"
            />
          </div>

          <div class="person-description">
            <span v-if="!editModeOn">
              {{ userData.description }}
            </span>
            <BRTextarea
              v-else
              :rows="2"
              :value.sync="userData.description"
              class="edit-input"
              @pressEnter="toggleEditMode"
            />
          </div>
        </div>
      </div>

      <div class="card-main">
        <ul class="navigation">
          <template v-if="getIsAdmin">
            <li
              v-for="item in adminNavigationList"
              :key="item.id"
              :class="{ active: item.id === activeNavigationItem }"
              class="navigation-item"
              @click="changeActiveItem(item)"
            >
              {{ item.title }}
            </li>
          </template>
          <template v-else>
            <li
              v-for="item in navigationList"
              :key="item.id"
              :class="{ active: item.id === activeNavigationItem }"
              class="navigation-item"
              @click="changeActiveItem(item)"
            >
              {{ item.title }}
            </li>
          </template>
        </ul>
      </div>

      <div class="card-footer">
        <div class="log-out" @click="logOut">
          <BRIcon
            class="log-out-icon"
            height="15"
            icon="exit"
            width="16"
          />
          <span class="log-out-text">Log Out</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
import BRButton from '@/components/BRComponents/BRButton.vue'
import BRIcon from '@/components/BRComponents/BRIcon.vue'
import BRInput from '@/components/BRComponents/BRInput.vue'
import BRTextarea from '@/components/BRComponents/BRTextarea.vue'
import images from '@/mixins/image'
import { ImageActions } from '@/services/image'

export default {
  name: 'UserCard',
  components: {
    BRButton,
    BRIcon,
    BRInput,
    BRTextarea
  },
  mixins: [images],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userData: Object.assign({}, this.user),
      editModeOn: false,
      imageFile: null,
      userAvatar: null,
      navigationItem: {
        id: 1,
        title: 'Watch List',
        component: 'ProfileWatchList'
      },
      navigationList: [
        {
          id: 1,
          title: 'Watch List',
          component: 'ProfileWatchList'
        },
        {
          id: 2,
          title: 'My reviews',
          component: 'ProfileReviews'
        }
      ],
      adminNavigationList: [
        {
          id: 1,
          title: 'Watch List',
          component: 'ProfileWatchList'
        },
        {
          id: 2,
          title: 'My reviews',
          component: 'ProfileReviews'
        },
        {
          id: 3,
          title: 'List of Users',
          component: 'UsersList'
        }
      ],
      activeNavigationItem: 1
    }
  },
  computed: {
    ...mapGetters(['getIsAdmin']),
    userName() {
      return this.userData.username ? this.userData.username : 'User'
    }
  },
  mounted() {
    this.userAvatar = this.getImage(this.userData.avatar, 'avatar', 200)
  },
  methods: {
    ...mapActions(['setUserDB']),
    toggleEditMode() {
      this.editModeOn = !this.editModeOn
    },
    changeImage({ target }) {
      this.imageFile = target.files[0]
      this.userAvatar = URL.createObjectURL(this.imageFile)
    },
    async saveImage() {
      if (!this.imageFile) {
        return
      }

      if (this.imageFile.size > 10000000) {
        this.userData.avatar = ''
        return
      }
      this.userData.avatar = await ImageActions.saveImage(this.imageFile)
    },
    async saveUserData() {
      await this.saveImage()
      const { error } = await api.users.updateUser(this.userData)

      if (!error) {
        this.setUserDB(this.userData)
      }

      this.toggleEditMode()
    },
    changeActiveItem(item = this.navigationItem) {
      this.activeNavigationItem = item.id
      this.$emit('changeActiveItem', item.component)
    },
    async logOut() {
      await firebase.auth().signOut()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.user-card.desktop {
  position: relative;
  border-radius: var(--border-radius-default);
  padding: 35px 24px 25px;
  box-shadow: var(--box-shadow-light);
  background-color: var(--content-bg-color);

  .save,
  .preferences {
    position: absolute;
    right: 14px;
    top: 14px;
  }

  .person-name .edit-input {
    margin: 0 -14px;
  }

  .person-name .edit-input ::v-deep .v-input__control {
    border-radius: var(--border-radius-default) !important;
  }
}

.save,
.preferences {
  cursor: pointer;
}

.card-main {
  @include breakpoint(medium) {
    border-bottom: var(--default-border);
    padding: 16px 0 24px;
  }
}

.save {
  min-width: 50px !important;
  max-width: 50px;
}

.edit-input {
  font-weight: var(--font-weight-normal);
}

.person-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
  height: 100px;
  min-width: fit-content;

  > img {
    border-radius: 50%;
    object-fit: cover;
  }

  #image-input {
    display: none;
  }
}

.edit-image {
  position: absolute;
  right: 40px;
  bottom: 0;
  color: var(--dark-icon-color);
  cursor: pointer;
}

.person-info {
  border-bottom: var(--default-border);
  padding-bottom: 16px;
  text-align: center;
}

.person-name {
  margin-bottom: 15px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-h4);
}

.person-description {
  overflow: hidden;
  font-size: var(--font-base);
  text-overflow: ellipsis;
  color: var(--secondary-text-color);

  @include breakpoint(medium) {
    margin: 0 -14px;
  }
}

.card-editable {
  .person-info {
    border-bottom: none;
    padding-bottom: 0;
  }

  .person-avatar {
    margin-bottom: 14px;
  }

  .person-name {
    margin-bottom: 7px;
  }
}

.navigation {
  padding: 16px 0;
  font-size: var(--font-base);
}

.navigation-item {
  border-radius: var(--border-radius-default);
  padding: 13px 25px;
  text-align: center;
  cursor: pointer;
}

.active {
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
}

.card-footer {
  display: grid;
  align-items: center;
  padding-top: 22px;
}

.log-out {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-default);
  padding: 13px 25px;
  cursor: pointer;

  &-icon {
    margin-right: 18px;
  }

  &-text {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  &:hover {
    color: var(--primary-text-color);
    background-color: var(--primary-bg-color);
  }
}

.user-card.mobile {
  .card-header {
    display: flex;
    margin-bottom: 28px;
    gap: 20px;
  }

  .person-avatar {
    margin: 0;
    height: auto;
  }

  .icon-container {
    margin-left: auto;
    min-width: fit-content;
  }

  .person-info {
    border: none;
    padding: 0;
    max-width: 180px;
    text-align: left;
  }

  .person-name {
    margin-bottom: 10px;
    font-size: var(--font-h4);
  }
}

.select {
  border-radius: var(--border-radius-default);

  &::v-deep.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding: 0 24px;
    box-shadow: 0 10px 15px rgba(52, 63, 116, 0.1);
    background-color: var(--content-bg-color);
  }

  &::v-deep .v-icon {
    color: var(--text-color);
  }
}

.select-item {
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
}

.selected-item {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  color: var(--text-color);
}

.desktop {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

.mobile {
  display: block;

  @include breakpoint(medium) {
    display: none;
  }
}

.btn-save {
  margin-left: auto;
}
</style>

<style lang="scss">
.v-menu__content {
  .v-list {
    background-color: var(--content-bg-color);
  }

  .menuable__content__active {
    border-radius: var(--border-radius-default);
    box-shadow: var(--secondary-box-shadow);
  }
}

</style>
