<template>
  <div :class="['casinos-list', { 'casinos-list-empty' : !$contentful.reviews }]">
    <div v-if="likedCasinos.length" class="content-wrapper">
      <CasinoCard
        v-for="casino in likedCasinos"
        :key="casino.id"
        :casino="casino"
        @updateBookmark="reset"
      />
      <BRButton
        v-if="isCanShowMore && !loading"
        class="show-more"
        color="var(--tertiary-bg-color)"
        dark
        @click="loadMore"
      >
        SHOW MORE
      </BRButton>
    </div>
    <div class="d-flex align-center">
      <v-progress-circular
        v-if="loading"
        class="loader-main"
        indeterminate
        size="50"
        width="5"
      />
    </div>
    <span v-if="!loading && !likedCasinos.length" class="empty-text">
      You don’t have any casinos in the watchlist. Add your first cryptocasino
      <router-link class="link" to="/">here</router-link>
    </span>
  </div>
</template>

<script>
import CasinoCard from '@/components/CasinoCard'
import api from '@/api'

export default {
  name: 'CasinoCardsList',
  components: {
    CasinoCard
  },
  data() {
    return {
      loading: false,
      isCanShowMore: false,
      likedCasinos: [],
      loadCasinosParams: {
        pageSize: 10,
        pageNumber: 0
      }
    }
  },
  mounted() {
    this.fetchLikedCasinos()
  },
  methods: {
    async fetchLikedCasinos() {
      this.loading = true

      const {
        data,
        error
      } = await api.users.getLikedCasinos('casino', this.loadCasinosParams)

      this.loading = false

      if (error) return

      const likedCasinos = this.$contentful.reviews.filter(review => data.includes(review.id))
      this.likedCasinos = [...this.likedCasinos, ...likedCasinos]
      this.isCanShowMore = data.length === this.loadCasinosParams.pageSize
    },
    loadMore() {
      this.loadCasinosParams.pageNumber += 1
      this.fetchLikedCasinos()
    },
    reset() {
      this.likedCasinos = []
      this.loadCasinosParams.pageNumber = 0
      this.fetchLikedCasinos()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.casinos-list {
  width: 100%;
}

.content-wrapper {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  gap: 10px;
}

.show-more {
  margin-top: 20px;
  width: 100%;

  &.blockreviews-button {
    border-radius: var(--border-radius-default);
    color: var(--secondary-text-color);
  }

  @include breakpoint(medium) {
    width: 252px;
  }
}

.casinos-list-empty {
  margin: auto;
}

.empty-text {
  display: block;
  margin: 100px auto 0;
  max-width: 260px;
  font-size: var(--font-h3);
  text-align: center;
  color: var(--secondary-text-color);

  .link {
    color: var(--primary-text-color);
  }
}
</style>
