<template>
  <div class="watch-list">
    <BRTabs
      :tabs="tabsList"
      class="tabs"
      @changeActiveTab="changeActiveTab"
    />
    <keep-alive>
      <component :is="currentTab" />
    </keep-alive>
  </div>
</template>

<script>
import BRTabs from '@/components/BRComponents/BRTabs.vue'
import CasinoCardsList from '@/components/CasinoCardsList'

export default {
  name: 'ProfileWatchList',
  components: {
    BRTabs,
    CasinoCardsList
  },
  data() {
    return {
      currentTab: 'CasinoCardsList',
      tabsList: [
        {
          id: 1,
          title: 'Casinos',
          name: 'CasinoCardsList'
        }
      ]
    }
  },
  methods: {
    changeActiveTab(componentName) {
      this.currentTab = componentName
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.watch-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoint-reverse(small) {
    align-items: center;
  }
}

.tabs {
  margin-bottom: 15px;
  padding: 6px;

  @include breakpoint(medium) {
    margin-bottom: 20px;
  }
}
</style>
