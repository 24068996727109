<template>
  <section v-if="getUserDB" class="profile">
    <aside class="profile-sidebar">
      <UserCard :user="getUserDB" @changeActiveItem="changeActiveItem" />
      <p v-if="!getIsAdmin" class="support-info">
        If you are a website owner and want to verify your profile,
        please contact a <a :href="`mailto:${contacts.email}`" class="link">customer support</a>
      </p>
    </aside>
    <div class="profile-main">
      <keep-alive>
        <component
          :is="currentItem"
          :user="getUserDB"
        />
      </keep-alive>
    </div>
    <BRButton
      class="log-out"
      color="var(--content-bg-color)"
      width="100%"
      @click="logOut"
    >
      <BRIcon
        height="15"
        icon="exit"
        width="16"
      />
      <span class="log-out-text">Log Out</span>
    </BRButton>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { contacts } from '../../public/messages/contacts'
import UserCard from '@/components/UserCard'
import ProfileWatchList from '@/components/ProfileWatchList'
import ProfileReviews from '@/components/ProfileReviews'
import UsersList from '@/components/UsersList'
import firebase from 'firebase'

export default {
  name: 'Profile',
  components: {
    UserCard,
    ProfileWatchList,
    ProfileReviews,
    UsersList
  },
  data() {
    return {
      contacts,
      currentItem: 'ProfileWatchList'
    }
  },
  computed: {
    ...mapGetters(['getUserDB', 'getIsAdmin'])
  },
  methods: {
    changeActiveItem(componentName) {
      this.currentItem = componentName
    },
    async logOut() {
      await firebase.auth().signOut()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.profile {
  @include container;

  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr;
  padding: 3px 0 6px;
  row-gap: 15px;

  @include breakpoint(medium) {
    grid-template-columns: 220px 1fr;
    padding: 0;
  }
}

.profile-sidebar {
  width: 100%;

  @include breakpoint(medium) {
    margin-right: 20px;
    max-width: 220px;
  }
}

.profile-main {
  width: 100%;
}

.support-info {
  display: none;
  margin-top: 15px;
  font-size: var(--font-sm);
  text-align: center;
  color: var(--secondary-text-color);

  .link {
    color: var(--primary-text-color);
  }

  @include breakpoint(medium) {
    display: block;
  }
}

.log-out {
  color: var(--secondary-text-color);

  ::v-deep .button-text {
    gap: 4px;
  }

  &-text {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  :hover {
    color: var(--primary-text-color);
  }

  @include breakpoint(medium) {
    display: none;
  }
}
</style>

<style lang="scss">
.log-out::before {
  background-color: var(--content-bg-color);
}
</style>
