<template>
  <div class="profile-main-wrapper">
    <h3 class="content-title">
      <slot name="title" />
    </h3>
    <div class="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileMainWrapper'
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.profile-main-wrapper {
  .content-title {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 20px;
    font-size: var(--font-h2);
    line-height: var(--line-height-standard);

    @include breakpoint(medium) {
      margin-top: 0;
      border-bottom: var(--default-border);
    }
  }

  .content {
    display: grid;

    @include breakpoint(medium) {
      padding-top: 20px;
    }
  }

  @include breakpoint(medium) {
    border-radius: var(--border-radius-default);
    padding: 24px 25px;
    width: 100%;
    background-color: var(--content-bg-color);
  }
}
</style>
