<template>
  <ProfileMainWrapper>
    <template #title>
      List of users
      <UsersListSearch
        :search-keyword.sync="searchKeyword"
        @filterUsers="filterUsers"
      />
    </template>
    <template #content>
      <div class="users-list-table">
        <div class="users-list-header">
          <span
            class="header-item header-name active"
            :class="sortDirection"
            @click="changeSortDirection"
          >
            Name
          </span>
          <span class="header-item header-email">Email</span>
          <span class="header-item header-date">Date</span>
          <span class="header-item header-roles">Roles</span>
          <span class="header-item header-actions">Action</span>
        </div>
        <div class="users-list">
          <div class="d-flex align-center">
            <v-progress-circular
              v-if="loading"
              class="loader-main"
              size="50"
              width="5"
              indeterminate
            />
          </div>
          <template v-if="!loading">
            <div
              v-for="user in users"
              :key="user.id"
              class="users-list-item"
            >
              <span class="user-name">{{ user.username }}</span>
              <span class="user-email">{{ user.email }}</span>
              <span class="user-date">{{ formatDate(user.registeredAt) }}</span>
              <span class="user-role">{{ user.role }}</span>
              <div class="user-actions">
                <v-menu
                  bottom
                  nudge-left="55"
                  nudge-bottom="25"
                  content-class="dropdown-menu"
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-icon class="actions">
                        mdi-dots-horizontal
                      </v-icon>
                    </div>
                  </template>

                  <ul class="action-list">
                    <li
                      v-for="(action, i) in userActionsList"
                      :key="i"
                      class="action-list-item"
                      @click="chooseAction(user, action.action)"
                    >
                      {{ action.title }}
                    </li>
                  </ul>
                </v-menu>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </ProfileMainWrapper>
</template>

<script>
import { formatDate } from '@/services/date'
import api from '@/api'
import ProfileMainWrapper from '@/components/ProfileMainWrapper'
import UsersListSearch from '@/components/UsersListSearch'

export default {
  name: 'UsersList',
  components: {
    ProfileMainWrapper,
    UsersListSearch
  },
  data() {
    return {
      loading: true,
      isSortDirection: false,
      users: null,
      loadUsersParams: {
        pageNumber: 0,
        pageSize: 10,
        keyword: '',
        sortBy: '',
        filterRole: '',
        filterVerify: ''
      },
      userActionsList: [
        {
          title: 'Make User',
          action: 'user'
        },
        {
          title: 'Give Editor role',
          action: 'editor'
        },
        {
          title: 'Give Admin role',
          action: 'admin'
        },
        {
          title: 'Make Verified',
          action: 'verify'
        },
        {
          title: 'Block user',
          action: 'delete'
        }
      ],
      searchTimer: null,
      searchKeyword: ''
    }
  },
  computed: {
    sortDirection() {
      return this.isSortDirection ? 'desc' : 'asc'
    }
  },
  watch: {
    searchKeyword(value) {
      this.loadUsersParams.keyword = value

      if (this.searchTimer) { clearTimeout(this.searchTimer) }

      this.searchTimer = setTimeout(() => {
        this.resetUsers()
        this.searchTimer = null
      }, 1200)
    },
    filterRole(value) {
      this.loadUsersParams.filterRole = value
      this.resetUsers()
    },
    filterVerify(value) {
      this.loadUsersParams.filterVerify = value
      this.resetUsers()
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    formatDate,
    async getUsers() {
      const { data, error } = await api.admin.getUsers(this.loadUsersParams)

      this.users = error ? [] : data

      this.loading = false
    },
    async deleteUser(userId) {
      const { error } = await api.admin.deleteUser(userId)

      if (!error) {
        this.getUsers()
      }
    },
    async setUserRole(userId, role) {
      const { error } = await api.admin.setUserRole(userId, role)

      if (!error) {
        this.getUsers()
      }
    },
    async verifyUser(userId, isVerified) {
      const { error } = await api.admin.verifyUser(userId, isVerified)

      if (!error) {
        this.getUsers()
      }
    },
    filterUsers(filter) {
      this.loading = true

      if (filter === 'editor' || filter === 'admin' || filter === 'user') {
        this.loadUsersParams.filterVerify = ''
        this.loadUsersParams.filterRole = filter
        this.getUsers()
        return
      }

      if (filter === 'verify') {
        this.loadUsersParams.filterRole = ''
        this.loadUsersParams.filterVerify = !this.loadUsersParams.filterVerify
        this.getUsers()
        return
      }

      if (filter === 'all') {
        this.loadUsersParams.filterRole = ''
        this.loadUsersParams.filterVerify = ''
        this.getUsers()
      }
    },
    resetUsers() {
      this.loading = true
      this.loadUsersParams.pageNumber = 0
      this.users = []
      this.getUsers()
    },
    changeSortDirection() {
      this.isSortDirection = !this.isSortDirection
      this.loadUsersParams.sortBy = this.sortDirection
      this.resetUsers()
    },
    chooseAction(user, action) {
      if (action === 'editor' || action === 'admin' || action === 'user') {
        this.setUserRole(user.id, action)
        return
      }

      if (action === 'verify') {
        this.verifyUser(user.id, !user.verified)
        return
      }

      if (action === 'delete') {
        const isConfirmed = confirm('Are you sure you want to delete this user?')

        if (!isConfirmed) { return }

        this.deleteUser(user.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.users-list-header,
.users-list-item {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 0.4fr 0.25fr;

  @include breakpoint-reverse(medium) {
    grid-template-columns: 1fr 0.25fr 0.25fr;
  }
}

.users-list-header {
  margin-bottom: 13px;
  padding-left: 24px;

  @include breakpoint-reverse(medium) {
    padding-left: 0;
  }
}

.header-item {
  font-size: var(--font-base);

  &.active {
    position: relative;

    &.desc::after,
    &.asc::after {
      content: "";
      position: absolute;
      bottom: 50%;
      margin-left: 1px;
      width: 6px;
      height: 3px;
      background-image: url("/public/img/arrow.svg");
      transform: translate(50%, 50%);
    }

    &.desc::after {
      transform: translate(50%, 50%) rotateX(180deg);
    }
  }
}

.header-name {
  cursor: pointer;

  @include breakpoint-reverse(medium) {
    padding-left: 10px;
  }
}

.header-date,
.header-email {
  @include breakpoint-reverse(medium) {
    display: none;
  }
}

.header-actions {
  text-align: center;
}

.users-list-item {
  grid-template-areas: "name email date role actions";
  border-radius: var(--border-radius-default);
  padding: 17px 0 17px 24px;
  font-size: var(--font-base);
  color: var(--secondary-text-color);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--tertiary-bg-color);
  }

  @include breakpoint-reverse(medium) {
    align-items: center;
    grid-template-areas:
      "name role actions"
      "email role actions";
    grid-template-columns: 1fr 0.25fr 0.25fr;
    padding: 10px;
  }
}

.user-name {
  grid-area: name;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-email {
  grid-area: email;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-date {
  grid-area: date;

  @include breakpoint-reverse(medium) {
    display: none;
  }
}

.user-role {
  grid-area: role;
}

.user-actions {
  grid-area: actions;
  text-align: center;

  .actions {
    color: var(--secondary-text-color);
    transform: rotate(90deg);
    cursor: pointer;
  }
}

.action-list {
  padding: 12px 0;
  background-color: var(--content-bg-color);

  &-item {
    padding: 10px 30px;
    font-size: var(--font-base);
    line-height: var(--line-height-sm);
    text-align: center;
    color: var(--secondary-text-color);
    cursor: pointer;

    &:hover {
      background-color: var(--tertiary-bg-color);
    }
  }

  .active {
    font-weight: var(--font-weight-bold);
    color: var(--primary-text-color);
  }
}

.dropdown-menu {
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow-default);
}
</style>
