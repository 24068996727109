<template>
  <article class="card">
    <button
      aria-label="Add to bookmarks"
      class="bookmark desktop"
      @click="toggleCasinoSubscription"
    >
      <BRIcon
        class="bookmark-marked"
        fill-color="var(--primary-icon-color)"
        height="20"
        icon="bookmark"
        stroke="transparent"
        width="15"
      />
    </button>
    <div :class="['main-information']">
      <img
        :alt="casinoData.name"
        :height="$vuetify.breakpoint.xs ? '40' : '66'"
        :src="getContentfulImage(casinoData.logo, 'casino')"
        :width="$vuetify.breakpoint.xs ? '40' : '66'"
        class="logo"
      >
      <div class="general-info">
        <p class="casino-name">
          {{ casinoData.name }}
          <button
            aria-label="Add to bookmarks"
            class="bookmark"
            @click="toggleCasinoSubscription"
          >
            <BRIcon
              class="bookmark-marked"
              fill-color="var(--primary-icon-color)"
              height="20"
              icon="bookmark"
              stroke="transparent"
              width="15"
            />
          </button>
        </p>
        <a :href="casinoData.website" class="casino-subtitle">Check bonuses</a>
      </div>
    </div>
    <div aria-label="Rate" class="total-rate mobile">
      <div>
        <span :style="{ color: getRatingColor(casinoData.totalRating) }" class="amount">
          {{ casinoData.totalRating }}</span>/<span class="of">5</span>
      </div>
      <BRIcon
        :height="$vuetify.breakpoint.xs ? '30' : '34'"
        :style="{ color: getRatingColor(casinoData.totalRating) }"
        :width="$vuetify.breakpoint.xs ? '26' : '30'"
        class="icon-rating"
        icon="rating"
      />
    </div>
    <div aria-label="Rate" class="total-rate desktop">
      <div>
        <span :style="{ color: getRatingColor(casinoData.totalRating) }" class="amount">
          {{ casinoData.totalRating }}</span>/<span class="of">5</span>
      </div>
      <a class="review-link" href="">Read reviews</a>
    </div>

    <div class="preview-page">
      <BRButton
        :height="$vuetify.breakpoint.xs ? '35' : '45'"
        :to="{ name: 'CasinoItemPage', params: { id: casinoData.id } }"
        :width="$vuetify.breakpoint.xs ? '100%' : '138px'"
        class="action"
        color="var(--primary-text-color)"
        dark
      >
        REVIEW PAGE
      </BRButton>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import images from '@/mixins/image'
import ratingColor from '@/mixins/ratingColor'
import bookmarkCasino from '@/mixins/bookmarkCasino'

export default {
  name: 'CasinoCard',
  mixins: [images, ratingColor, bookmarkCasino],
  props: {
    casino: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      casinoData: this.casino
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.card {
  @include content;

  display: grid;
  align-items: flex-start;
  grid-gap: 22px 44px;
  grid-template-areas:
    "information rate"
    "preview preview";
  grid-template-columns: 1fr auto;
  padding: 16px 20px;

  @include breakpoint(medium) {
    display: grid;
    align-items: center;
    grid-template-areas: "bookmark information rate preview";
    grid-template-columns: 32px 2fr 95px 1fr;
    padding: 12px;
    gap: 4px;
  }

  @include breakpoint(large) {
    grid-template-columns: 55px 2fr 95px 1fr;
    padding: 12px 22px;
  }
}

.action {
  border: none;
  background-color: var(--primary-bg-color);
  transition: background-color 0.3s, color 0.3s;

  ::v-deep .button-text {
    gap: 6px;
  }
}

.general-info {
  width: 100%;
}

.bookmark {
  display: flex;
  grid-area: bookmark;
  min-width: 9px;

  &::v-deep .blockreviews-icon {
    stroke: var(--text-color);
  }

  &.desktop {
    display: none;

    svg {
      min-width: 15px;
    }

    @include breakpoint(medium) {
      display: flex;
      padding: 0 6px 0 10px;
    }

    @include breakpoint(large) {
      display: flex;
      padding: 0 32px 0 10px;
    }
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.main-information {
  display: flex;
  grid-area: information;
  gap: 12px;

  .casino-name {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h4);
    gap: 10px;
  }

  .casino-subtitle {
    display: block;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-xs);
    text-transform: uppercase;
    color: var(--link-color);
  }

  @include breakpoint(medium) {
    align-items: center;
    gap: 20px;
  }
}

.logo {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  object-fit: cover;

  @include breakpoint(medium) {
    width: 66px;
    height: 66px;
    min-width: 66px;
  }
}

.total-rate {
  display: flex;
  align-items: center;
  grid-area: rate;
  min-width: 60px;
  gap: 8px;

  .amount {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);
    color: var(--primary-text-color);
  }

  .of {
    font-size: var(--font-base);
  }

  .review-link {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-sm);
    color: var(--text-color);
  }
}

.total-rate.mobile {
  display: flex;

  @include breakpoint(medium) {
    display: none;
  }
}

.total-rate.desktop {
  display: none;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
  }
}

.preview-page {
  display: flex;
  justify-content: flex-end;
  grid-area: preview;

  .blockreviews-button {
    width: 100%;

    @include breakpoint(medium) {
      width: 140px;
    }
  }

  .arrow-down {
    margin-left: 5px;
    max-width: 10px;
  }
}

.icon-rating {
  color: var(--primary-icon-color);
}
</style>
