<template>
  <div>
    <ProfileMainWrapper>
      <template #title>
        Your reviews
      </template>
      <template #content>
        <div class="comment-container">
          <template v-if="comments && comments.length">
            <BRActionsComments
              v-for="comment in comments"
              :key="comment.id"
              :data-review="comment"
              editable
              is-show-casino-name
              @editComment="editComment"
              @updateComments="resetUserComments"
            />
          </template>
          <template v-if="!loading && !comments.length">
            <span class="empty-text">
              You don’t have any review in the review list
            </span>
          </template>
        </div>
        <BRButton
          v-if="isCanShowMore && !loading"
          :color="$vuetify.breakpoint.xs ? 'var(--tertiary-bg-color)' : 'var(--content-bg-color)'"
          class="show-more"
          @click="getUserComments"
        >
          SHOW MORE
        </BRButton>
        <div class="d-flex align-center">
          <v-progress-circular
            v-if="loading"
            class="loader-main"
            indeterminate
            size="50"
            width="5"
          />
        </div>
      </template>
    </ProfileMainWrapper>
    <UserCommentForm
      :comment="selectedComment"
      :is-show-comment-dialog.sync="isShowCommentDialog"
      :user="user"
      @updateComments="resetUserComments"
    />
  </div>
</template>

<script>
import api from '@/api'
import ProfileMainWrapper from '@/components/ProfileMainWrapper'
import UserCommentForm from '@/components/UserCommentForm'
import BRActionsComments from '@/components/BRComponents/BRActionsComments.vue'

export default {
  name: 'ProfileNotifications',
  components: {
    BRActionsComments,
    ProfileMainWrapper,
    UserCommentForm
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      isCanShowMore: false,
      isShowCommentDialog: false,
      comments: [],
      loadCommentsParams: {
        pageNumber: 0,
        pageSize: 10
      },
      selectedComment: null
    }
  },
  mounted() {
    this.getUserComments()
  },
  methods: {
    async getUserComments() {
      this.loading = true
      const {
        data,
        error
      } = await api.comments.getUserComments(this.loadCommentsParams)
      if (error) {
        this.comments = []
        return
      }

      if (data.length) {
        this.comments = [...this.comments, ...data]
      }

      this.isCanShowMore = data.length === this.loadCommentsParams.pageSize

      if (this.isCanShowMore) {
        this.loadCommentsParams.pageNumber += 1
      }

      this.loading = false
    },
    resetUserComments() {
      this.loading = true
      this.loadCommentsParams.pageNumber = 0
      this.comments = []
      this.getUserComments()
    },
    editComment(comment) {
      this.isShowCommentDialog = true
      this.selectedComment = comment
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.show-more {
  margin-top: 20px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  color: var(--secondary-text-color);
}

.comment-container {
  display: grid;
  border-radius: var(--border-radius-default);
  padding: 20px;
  background-color: var(--content-bg-color);
  gap: 15px;

  @include breakpoint(medium) {
    padding: 0;
  }
}

.empty-text {
  display: block;
  margin: auto;
  font-size: var(--font-base);
  color: var(--secondary-text-color);

  .link {
    color: var(--primary-text-color);
  }
}
</style>
