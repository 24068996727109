<template>
  <div class="search">
    <BRInput
      class="search-input"
      placeholder="Search user"
      :value.sync="searchValue"
      @click:append="searchValue = ''"
    >
      <v-icon class="mr-1" color="var(--default-icon-color)">
        mdi-magnify
      </v-icon>
    </BRInput>
    <v-menu
      bottom
      nudge-left="45"
      nudge-bottom="25"
      content-class="dropdown-menu"
    >
      <template #activator="{ on }">
        <div v-on="on">
          <BRIcon class="filter" icon="preferences" />
        </div>
      </template>

      <ul class="filter-list">
        <li
          v-for="(filter, i) in searchFilterList"
          :key="i"
          class="filter-list-item"
          @click="chooseFilter(filter.filter)"
        >
          {{ filter.title }}
        </li>
      </ul>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'UsersListSearch',
  props: {
    searchKeyword: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      searchValue: this.searchKeyword,
      searchFilterList: [
        {
          title: 'Show all',
          filter: 'all'
        },
        {
          title: 'Show editors',
          filter: 'editor'
        },
        {
          title: 'Show admins',
          filter: 'admin'
        },
        {
          title: 'Show verified',
          filter: 'verify'
        },
        {
          title: 'Show users',
          filter: 'user'
        }
      ]
    }
  },
  watch: {
    searchValue() {
      this.$emit('update:searchKeyword', this.searchValue)
    }
  },
  methods: {
    chooseFilter(filter) {
      this.$emit('filterUsers', filter)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.search {
  display: flex;
  align-items: center;
  justify-content: center;

  .search-input {
    width: 100%;
    min-width: 226px;
    font-weight: var(--font-weight-normal);

    &::v-deep .v-input__control > .v-input__slot {
      padding-right: 12px;
      padding-left: 20px;
    }

    &::v-deep .v-input__prepend-inner .v-icon {
      font-size: 18px;
    }

    &::v-deep .v-input__append-inner {
      width: 20px;

      .v-icon {
        font-size: 14px;
      }
    }
  }

  .filter {
    margin-left: 19px;
    cursor: pointer;
  }

  @include breakpoint-reverse(medium) {
    padding-top: 20px;
  }
}

.filter-list {
  padding: 12px 0;
  background-color: var(--content-bg-color);

  &-item {
    padding: 10px 30px;
    font-size: var(--font-base);
    line-height: var(--line-height-sm);
    text-align: center;
    color: var(--secondary-text-color);
    cursor: pointer;

    &:hover {
      background-color: var(--tertiary-bg-color);
    }
  }

  .active {
    font-weight: var(--font-weight-bold);
    color: var(--primary-text-color);
  }
}

.dropdown-menu {
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow-default);
}
</style>
