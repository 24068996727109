<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <v-textarea
      v-model="inputValue"
      :error="error"
      :height="height"
      :hide-details="hideDetails"
      :outlined="outlined"
      :placeholder="placeholder"
      :rows="rows"
      :rules="textSizeLimitRule"
      :solo="solo"
      auto-grow
      background-color="var(--input-bg-color)"
      class="blockreviews-textarea"
      dense
      flat
      no-resize
      @keyup.enter="$emit('pressEnter')"
    >
      <template #message="{message}">
        <p class="error-message">
          {{ message }}
        </p>
      </template>
    </v-textarea>
  </div>
</template>

<script>

export default {
  name: 'BRTextarea',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    rows: {
      type: Number || String,
      required: false,
      default: 3
    },
    solo: {
      type: Boolean,
      required: false,
      default: true
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: [String, Number],
      required: false,
      default: 'auto'
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    isShowCounter: {
      type: Boolean,
      required: false,
      default: false
    },
    textSizeLimitRule: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.blockreviews-textarea::v-deep .v-input__control {
  border-radius: var(--border-radius-default) !important;
}

.blockreviews-textarea::v-deep .v-text-field__slot {
  font-size: var(--font-base);
}

.blockreviews-textarea::v-deep textarea::placeholder {
  color: var(--secondary-text-color) !important;
}

.blockreviews-textarea::v-deep textarea {
  color: var(--secondary-text-color) !important;
}

.error-message {
  margin-top: 10px;
  color: var(--copyrights-color);
  background-color: transparent;
}
</style>
