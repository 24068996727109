import { imageApi } from '@/plugins/axios'

class ImageActions {
  static async saveImage(file) {
    if (file.size > 10000000) {
      return Promise.reject(new Error('Use an image smaller than 10Mb'))
    }
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await imageApi.post('/', formData)

    return data
  }
}

export { ImageActions }
